import React, { useState, useCallback, useEffect, useContext } from "react";
import SectionHeader from "../SectionHeader";
import { Tab, TabContent, Tabs, TabsBar } from "../Tabs";
import { TaskContent } from "../AssetDetails";
import TaskFilteredDropdown from "../AssetDetails/TaskFilteredDropdown";
import MeatballTaskRecent from "./MeatballTaskRecent";
import BatchTasks from "./BatchTasks";
import {
  fetchTasksList,
  fetchTasksPrototypesList,
  getReadyTasks,
  getRecentTasks,
  getGroupTasks,
  getBatchTasks,
  fetchRecurrenceTasksList,
  getRecurrenceTasks,
  fetchGroupTasks,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { TaskState } from "../../constants/Tasks";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./Tasks.module.scss";
import RecurrenceTasks from "./RecurrenceTasks";
import jwtDecode from "jwt-decode";
import GroupTasks from "./GroupTasks";
import { FilterDropdown } from "../Dropdown";
import useDeepCompareEffect from "use-deep-compare-effect";
import { filterItems } from "../AssetDetails/utils";
import Loader from "../Loader";
import Button from "../Button";

const getUniqueItemsByProperties = (items, propName) => {
  return items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) => foundItem[propName] === item[propName])
  );
};

const Tasks = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const paramsType = searchParams.get("type");
  const paramsPart = searchParams.get("part");
  const paramsFrom = searchParams.get("from");
  const paramsTo = searchParams.get("to");
  const paramsTaskTypes = searchParams.get("tasktypes");
  const paramsCompany = searchParams.get("companyId");

  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(
    paramsType === "closed" || paramsType === "result" ? "ready" : "recent"
  );

  const recentItems = useSelector(getRecentTasks);
  const readyItems = useSelector(getReadyTasks);
  const groupItems = useSelector(getGroupTasks);
  const batchItems = useSelector(getBatchTasks);
  const recurrenceItems = useSelector(getRecurrenceTasks);
  const allChoosen = useSelector((state) => state.buildings.choosenBuildings);

  const loadingTasks = useSelector((state) => state.tasks.loading);
  const loadingRecentTasks = useSelector((state) => state.tasks.loadingRecent);
  const loadingReadyTasks = useSelector((state) => state.tasks.loadingReady);
  const loadingTaskPrototypes = useSelector(
    (state) => state.taskPrototypes.loading
  );
  const userRole = useSelector((state) => state.auth.info?.role);
  const templatePrototypes = useSelector(
    (state) => state.taskPrototypes.items ?? []
  );

  const taskTemplateOptions =
    templatePrototypes
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((prototype) => {
        return {
          value: prototype.id,
          label: prototype.name,
          global: prototype.global,
        };
      }) ?? [];

  const templateOptions =
    groupItems
      ?.map(({ taskPrototype }) => {
        return {
          value: taskPrototype.id,
          label: taskPrototype.name,
          global: taskPrototype.global,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const recurrenceTaskTemplateOptions =
    recurrenceItems
      ?.map(({ taskPrototype }) => {
        return {
          value: taskPrototype.id,
          label: taskPrototype.name,
          global: taskPrototype.global,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const recurrenceTaskStateOptions = [
    {
      value: "pending",
      label: t("pending"),
    },
    {
      value: "started",
      label: t("started"),
    },
    {
      value: "finished",
      label: t("finished group task"),
    },
    {
      value: "suspended",
      label: t("suspended"),
    },
  ];

  const applyOpenTasksFilter = (part) => {
    switch (part) {
      case "TODO":
        return [{ value: "TODO", label: t("one-off tasks due") }];
      case "OVERDUE":
        return [{ value: "OVERDUE", label: t("one-off tasks overdue") }];
      case "RECURRING":
        return [{ value: "RECURRING", label: t("recurring tasks") }];
      default:
        return [];
    }
  };

  const applyClosedTasksFilter = (type, part) => {
    if (type === "closed") {
      switch (part) {
        case "COMPLETED":
          return [
            {
              value: "COMPLETED_WITH_DISCREPANCIES",
              label: t("completed tasks with nonconformities"),
            },
            {
              value: "COMPLETED_WITHOUT_DISCREPANCIES",
              label: t("completed tasks without nonconformities"),
            },
          ];
        case "DONE_TOO_LATE":
          return [
            {
              value: "DONE_TOO_LATE_WITH_DISCREPANCIES",
              label: t("done too late with nonconformities"),
            },
            {
              value: "DONE_TOO_LATE_WITHOUT_DISCREPANCIES",
              label: t("done too late without nonconformities"),
            },
          ];
        case "MISSED":
          return [{ value: "MISSED", label: t("missed") }];
        case "CANCELLED":
          return [{ value: "CANCELLED", label: t("cancelled") }];
        case "CANT_DO":
          return [
            { value: "CANT_DO", label: t("tasks couldn't be completed") },
          ];
        default:
          return [];
      }
    } else if (type === "result") {
      switch (part) {
        case "tasksWithoutDiscrepancies":
          return [
            {
              value: "COMPLETED_WITHOUT_DISCREPANCIES",
              label: t("completed tasks without nonconformities"),
            },
            {
              value: "DONE_TOO_LATE_WITHOUT_DISCREPANCIES",
              label: t("done too late without nonconformities"),
            },
          ];
        case "tasksWithDiscrepancies":
          return [
            {
              value: "COMPLETED_WITH_DISCREPANCIES",
              label: t("completed tasks with nonconformities"),
            },
            {
              value: "DONE_TOO_LATE_WITH_DISCREPANCIES",
              label: t("done too late with nonconformities"),
            },
          ];
        default:
          return [];
      }
    } else {
      return [];
    }
  };

  const [filteredRecentItems, setFilteredRecentItems] = useState([]);
  const [filteredReadyItems, setFilteredReadyItems] = useState([]);
  const [filteredRecurrenceItems, setFilteredRecurrenceItems] = useState([]);
  const [filteredGroupTasksItems, setFilteredGroupTasksItems] = useState([]);

  const [recurrenceTaskTypeFilter, setRecurrenceTaskTypeFilter] = useState([]);
  const [recurrenceTaskStateFilter, setRecurrenceTaskStateFilter] = useState(
    []
  );
  const [groupTasksTypeFilter, setGroupTasksTypeFilter] = useState([]);
  const [groupTasksStateFilter, setGroupTasksStateFilter] = useState([]);
  const [currentOpenTasksFilters, setCurrentOpenTasksFilters] = useState(
    searchParams && paramsType === "open"
      ? {
          fromDateFilter: new Date(paramsFrom),
          toDateFilter: new Date(paramsTo),
          templateFilter: paramsTaskTypes
            ? taskTemplateOptions.filter((option) =>
                paramsTaskTypes.split(",").includes(option.value)
              )
            : [],
          stateFilter: applyOpenTasksFilter(paramsPart),
          nearlyMissedFilter: false,
        }
      : searchParams && !!paramsCompany
      ? {
          nearlyMissedFilter: true,
        }
      : {}
  );
  const [currentClosedTasksFilters, setCurrentClosedTasksFilters] = useState(
    searchParams && (paramsType === "closed" || paramsType === "result")
      ? {
          fromDateFilter: new Date(paramsFrom),
          toDateFilter: new Date(paramsTo),
          templateFilter: paramsTaskTypes
            ? taskTemplateOptions.filter((option) =>
                paramsTaskTypes.split(",").includes(option.value)
              )
            : [],
          stateFilter: applyClosedTasksFilter(paramsType, paramsPart),
        }
      : {}
  );
  const [recentTasksCounter, setRecentTasksCounter] = useState(0);
  const [readyTasksCounter, setReadyTasksCounter] = useState(0);
  const [sort, setSort] = useState("date");
  const [recurringTasksSort, setRecurringTasksSort] = useState("ASSET_PATH");
  const [groupTasksSort, setGroupTasksSort] = useState("ASSET_PATH");

  const info = useSelector((state) => state.auth.info);
  const isAdmin = info?.token
    ? jwtDecode(info?.token).role === "SUPER_ADMIN" ||
      jwtDecode(info?.token).role === "CUSTOMER_ADMIN" ||
      jwtDecode(info?.token).role === "ADMIN"
    : false;

  const handleChange = useCallback(
    (newValue) => {
      setSelectedTab(newValue);
      setCurrentOpenTasksFilters({});
      setCurrentClosedTasksFilters({});
      setRecurrenceTaskTypeFilter([]);
      setRecurrenceTaskStateFilter([]);
      setGroupTasksTypeFilter([]);
      setGroupTasksStateFilter([]);
    },
    [setSelectedTab]
  );

  const toggleSort = () => {
    if (sort === "date") {
      setSort("path");
    } else {
      setSort("date");
    }
  };

  const toggleRecurringTasksSort = () => {
    if (recurringTasksSort === "ASSET_PATH") {
      setRecurringTasksSort("TASK_TYPE");
    } else {
      setRecurringTasksSort("ASSET_PATH");
    }
  };

  const toggleGroupTasksSort = () => {
    if (groupTasksSort === "ASSET_PATH") {
      setGroupTasksSort("TASK_TYPE");
    } else {
      setGroupTasksSort("ASSET_PATH");
    }
  };

  const handleRecentFilteredChanged = useCallback(
    (items, currentOpenTasksFilters) => {
      setFilteredRecentItems(items);
      setCurrentOpenTasksFilters(currentOpenTasksFilters);
    },
    [setFilteredRecentItems]
  );

  const handleReadyFilteredChanged = useCallback(
    (items, currentClosedTasksFilters) => {
      setFilteredReadyItems(items);
      setCurrentClosedTasksFilters(currentClosedTasksFilters);
    },
    [setFilteredReadyItems]
  );

  useEffect(() => {
    setRecentTasksCounter(filteredRecentItems.length);
  }, [filteredRecentItems.length, selectedTab]);

  useEffect(() => {
    setReadyTasksCounter(filteredReadyItems.length);
  }, [filteredReadyItems.length, selectedTab]);

  useEffect(() => {
    dispatch(fetchTasksPrototypesList());
  }, []);

  useDeepCompareEffect(() => {
    dispatch(
      fetchTasksList(
        TaskState.TODO,
        allChoosen.map((el) => el.id)
      )
    );
    dispatch(
      fetchTasksList(
        TaskState.DONE,
        allChoosen.map((el) => el.id)
      )
    );
    dispatch(fetchGroupTasks(allChoosen.map((el) => el.id)));
  }, [allChoosen, selectedTab]);

  useEffect(() => {
    if (!!recentItems) {
      setFilteredRecentItems(
        filterItems(
          recentItems,
          currentOpenTasksFilters.fromDateFilter,
          currentOpenTasksFilters.toDateFilter,
          currentOpenTasksFilters.stateFilter,
          currentOpenTasksFilters.templateFilter,
          currentOpenTasksFilters.nameFilter,
          false,
          currentOpenTasksFilters.nearlyMissedFilter
        )
      );
    }
  }, [recentItems, currentOpenTasksFilters, selectedTab]);

  useEffect(() => {
    if (!!readyItems) {
      setFilteredReadyItems(
        filterItems(
          readyItems,
          currentClosedTasksFilters.fromDateFilter,
          currentClosedTasksFilters.toDateFilter,
          currentClosedTasksFilters.stateFilter,
          currentClosedTasksFilters.templateFilter,
          currentClosedTasksFilters.nameFilter,
          true
        )
      );
    }
  }, [readyItems, currentClosedTasksFilters, selectedTab]);

  useEffect(() => {
    setFilteredRecurrenceItems(recurrenceItems);
  }, [recurrenceItems, selectedTab]);

  useEffect(() => {
    setFilteredGroupTasksItems(groupItems);
  }, [groupItems, selectedTab]);

  useDeepCompareEffect(() => {
    dispatch(
      fetchRecurrenceTasksList(
        allChoosen.map((el) => el.id),
        recurrenceTaskTypeFilter
          ? recurrenceTaskTypeFilter.map((option) => option.value)
          : [],
        recurrenceTaskStateFilter
          ? recurrenceTaskStateFilter.map((option) => option.value)
          : [],
        recurringTasksSort
      )
    );
  }, [
    allChoosen,
    recurrenceTaskTypeFilter,
    recurrenceTaskStateFilter,
    recurringTasksSort,
    selectedTab,
  ]);

  useDeepCompareEffect(() => {
    dispatch(
      fetchGroupTasks(
        allChoosen.map((el) => el.id),
        groupTasksTypeFilter
          ? groupTasksTypeFilter.map((option) => option.value)
          : [],
        groupTasksStateFilter
          ? groupTasksStateFilter.map((option) => option.value)
          : [],
        groupTasksSort
      )
    );
  }, [
    allChoosen,
    groupTasksTypeFilter,
    groupTasksStateFilter,
    groupTasksSort,
    selectedTab,
  ]);

  const getTasks = () => {
    dispatch(
      fetchTasksList(
        TaskState.TODO,
        allChoosen.map((el) => el.id)
      )
    );
    dispatch(
      fetchTasksList(
        TaskState.DONE,
        allChoosen.map((el) => el.id)
      )
    );
  };

  const calculateRecurrencePeriod = (recAmount, recInterval) => {
    switch (recInterval) {
      case "DAYS":
        return recAmount;
      case "WEEKS":
        return recAmount * 7;
      case "MONTHS":
        return recAmount * 30;
      case "QUARTER_YEAR":
        return recAmount * 90;
      case "HALF_YEAR":
        return recAmount * 182;
      case "YEARS":
        return recAmount * 365;
      default:
        return 0;
    }
  };

  const sortRecentTasks = () => {
    if (sort === "date") {
      return filteredRecentItems
        .sort((a, b) =>
          a.assetPath.join(",").localeCompare(b.assetPath.join(","))
        )
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort(
          (a, b) =>
            calculateRecurrencePeriod(
              a?.scheduler?.recurringInterval,
              a?.scheduler?.intervalUnit
            ) -
            calculateRecurrencePeriod(
              b?.scheduler?.recurringInterval,
              b?.scheduler?.intervalUnit
            )
        )
        .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
    } else {
      return filteredRecentItems
        .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort(
          (a, b) =>
            calculateRecurrencePeriod(
              a?.scheduler?.recurringInterval,
              a?.scheduler?.intervalUnit
            ) -
            calculateRecurrencePeriod(
              b?.scheduler?.recurringInterval,
              b?.scheduler?.intervalUnit
            )
        )
        .sort((a, b) =>
          a.assetPath.join(",").localeCompare(b.assetPath.join(","))
        );
    }
  };

  const sortReadyTasks = () => {
    if (sort === "date") {
      return filteredReadyItems.sort(
        (a, b) =>
          new Date(b.history.at(-1).occurredAt) -
          new Date(a.history.at(-1).occurredAt)
      );
    } else {
      return filteredReadyItems
        .sort(
          (a, b) =>
            new Date(b.history.at(-1).occurredAt) -
            new Date(a.history.at(-1).occurredAt)
        )
        .sort((a, b) =>
          a.assetPath.join(",").localeCompare(b.assetPath.join(","))
        );
    }
  };

  return (
    <>
      <SectionHeader>
        <div className={styles.title}>{t("tasks")}</div>
      </SectionHeader>
      <Tabs onChange={handleChange} initialValue={selectedTab}>
        <TabsBar>
          <Tab key={"recent"} value={"recent"}>
            <div className={styles.row_tab}>
              {t("to do tasks", "title")}
              <span className={styles.circle}>
                <span className={styles.number}>{recentTasksCounter}</span>
              </span>
            </div>
          </Tab>
          <Tab key={"ready"} value={"ready"}>
            <div className={styles.row_tab}>
              {t("finished tasks", "title")}
              <span className={styles.circle}>
                <span className={styles.number}>{readyTasksCounter}</span>
              </span>
            </div>
          </Tab>
          <Tab key={"batch"} value={"batch"}>
            <div className={styles.row_tab}>
              {t("batch tasks", "title")}
              <span className={styles.circle}>
                <span className={styles.number}>{batchItems?.length}</span>
              </span>
            </div>
          </Tab>
          {isAdmin && (
            <Tab key={"recurrence"} value={"recurrence"}>
              <div className={styles.row_tab}>
                {t("recurrence tasks", "title")}
                <span className={styles.circle}>
                  <span className={styles.number}>
                    {filteredRecurrenceItems?.length}
                  </span>
                </span>
              </div>
            </Tab>
          )}
          <Tab key={"group"} value={"group"}>
            <div className={styles.row_tab}>
              {t("group tasks", "title")}
              <span className={styles.circle}>
                <span className={styles.number}>{groupItems?.length}</span>
              </span>
            </div>
          </Tab>
        </TabsBar>
        <TabContent index="recent">
          {(loadingTasks ||
            loadingRecentTasks ||
            loadingReadyTasks ||
            loadingTaskPrototypes) && <Loader />}
          <div className={styles.filters_section}>
            <TaskFilteredDropdown
              onItemsFiltered={handleRecentFilteredChanged}
              items={recentItems}
              sort={sort}
              toggleSort={toggleSort}
              openTasks
              defaultTaskTypes={
                paramsType === "open"
                  ? currentOpenTasksFilters.templateFilter
                  : []
              }
              defaultState={
                paramsType === "open" ? currentOpenTasksFilters.stateFilter : []
              }
              nearlyMissedOnly={!!paramsCompany}
            />
            <MeatballTaskRecent />
          </div>
          <TaskContent
            items={sortRecentTasks()}
            displayBorder
            disabled={false}
          />
        </TabContent>
        <TabContent index="ready">
          {(loadingTasks ||
            loadingRecentTasks ||
            loadingReadyTasks ||
            loadingTaskPrototypes) && <Loader />}
          <div className={styles.filters_section}>
            <TaskFilteredDropdown
              onItemsFiltered={handleReadyFilteredChanged}
              items={readyItems}
              sort={sort}
              toggleSort={toggleSort}
              closedTasks
              defaultFrom={
                paramsType === "closed" || paramsType === "result"
                  ? currentClosedTasksFilters.fromDateFilter
                  : null
              }
              defaultTo={
                paramsType === "closed" || paramsType === "result"
                  ? currentClosedTasksFilters.toDateFilter
                  : null
              }
              defaultTaskTypes={
                paramsType === "closed" || paramsType === "result"
                  ? currentClosedTasksFilters.templateFilter
                  : []
              }
              defaultState={
                paramsType === "closed" || paramsType === "result"
                  ? currentClosedTasksFilters.stateFilter
                  : []
              }
            />
          </div>
          <TaskContent
            items={sortReadyTasks()}
            displayBorder
            disabled={true}
            isFinishedEditable={
              userRole === "SUPER_ADMIN" || userRole === "ADMIN"
            }
          />
        </TabContent>
        <TabContent index="batch">
          <BatchTasks getTasks={getTasks} />
        </TabContent>
        <TabContent index="recurrence">
          <div className={styles.filters_section}>
            <div className={styles.filters}>
              <div className={styles.sort}>
                <Button
                  onClick={toggleRecurringTasksSort}
                  variant="orange"
                  size="s"
                >
                  {recurringTasksSort === "ASSET_PATH"
                    ? t("sort by task type")
                    : t("sort by path")}
                </Button>
              </div>
              <FilterDropdown
                value={recurrenceTaskStateFilter}
                options={recurrenceTaskStateOptions}
                onSelect={(value) => setRecurrenceTaskStateFilter(value)}
                placeholder={t("task state")}
                isMultiple={true}
                wide
              />
              <FilterDropdown
                value={recurrenceTaskTypeFilter}
                options={getUniqueItemsByProperties(
                  recurrenceTaskTemplateOptions,
                  "value"
                )}
                onSelect={(value) => setRecurrenceTaskTypeFilter(value)}
                placeholder={t("task template")}
                isMultiple={true}
                wide
              />
            </div>
          </div>
          <RecurrenceTasks
            items={filteredRecurrenceItems}
            filters={{
              taskTypeFilter: recurrenceTaskTypeFilter.map(
                (option) => option.value
              ),
              statusFilter: recurrenceTaskStateFilter.map(
                (option) => option.value
              ),
              sort: recurringTasksSort,
            }}
          />
        </TabContent>
        <TabContent index="group">
          <div className={styles.filters_section}>
            <div className={styles.filters}>
              <div className={styles.sort}>
                <Button
                  onClick={toggleGroupTasksSort}
                  variant="orange"
                  size="s"
                >
                  {groupTasksSort === "ASSET_PATH"
                    ? t("sort by task type")
                    : t("sort by path")}
                </Button>
              </div>
              <FilterDropdown
                value={groupTasksStateFilter}
                options={recurrenceTaskStateOptions}
                onSelect={(value) => setGroupTasksStateFilter(value)}
                placeholder={t("task state")}
                isMultiple={true}
                wide
              />
              <FilterDropdown
                value={groupTasksTypeFilter}
                options={getUniqueItemsByProperties(templateOptions, "value")}
                onSelect={(value) => setGroupTasksTypeFilter(value)}
                placeholder={t("task template")}
                isMultiple={true}
                wide
              />
            </div>
          </div>
          <GroupTasks
            items={filteredGroupTasksItems}
            filters={{
              taskTypeFilter: groupTasksTypeFilter.map(
                (option) => option.value
              ),
              statusFilter: groupTasksStateFilter.map((option) => option.value),
              sort: groupTasksSort,
            }}
          />
        </TabContent>
      </Tabs>
    </>
  );
};

export default Tasks;
