import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import PropTypes from "prop-types";
import MeatballMenu from "../Access/MeatballMenu";
import Icon from "../Icon";
import AddUnitsModal from "./AddUnitsModal";
import DeleteUnitModal from "./DeleteUnitModal";
import { I18nContext } from "../../i18n/I18nContext";
import styles from "./UnitContent.module.scss";

const UnitContent = ({ items, canEdit }) => {
  const { t } = useContext(I18nContext);
  const [openEditUnitModal, setEditUnitModalOpen] = useState(false);
  const [openDeleteUnitModal, setDeleteUnitModalOpen] = useState(false);
  const [unitSelected, setSelectedUnit] = useState(null);
  const [meatballOpened, setMeatballOpened] = useState(null);
  const isPrivileged = useSelector(
    (state) => state.auth.info?.companyPrivileged
  );

  const meatBallsItems = (unit) => [
    {
      label: t("edit"),
      show:
        !unit.deprecated && (isPrivileged || (!isPrivileged && !unit.global)),
      onClick: () => {
        setEditUnitModalOpen(true);
        setSelectedUnit(unit);
      },
      icon: <Icon name="editPencil-orange" />,
    },
    {
      label: t("delete"),
      show:
        !unit.deprecated && (isPrivileged || (!isPrivileged && !unit.global)),
      onClick: () => {
        setDeleteUnitModalOpen(true);
        setSelectedUnit(unit);
      },
      icon: <Icon name="trash-orange" />,
    },
  ];

  return (
    <div className={styles.task_container}>
      {items?.map((item) => {
        return (
          <div
            key={item.id}
            className={cx(styles.row, {
              [styles.visible_for_sub_companies]: item.global,
            })}
          >
            <div className={styles.title}>
              {item.name.includes("bluetooth device")
                ? t(item.name)
                : item.name}
            </div>
            <div className={styles.symbol}>{item.symbol}</div>
            <div className={styles.symbol}>min: {item.range.lowerBound}</div>
            <div className={styles.symbol}>max: {item.range.upperBound}</div>
            <div className={styles.date}>
              <div className={styles.create}>{item.updatedAt}</div>
              <div className={styles.meatball}>
                <MeatballMenu
                  items={meatBallsItems(item)}
                  disabled={
                    item.deprecated ||
                    (!isPrivileged && item.global) ||
                    !canEdit
                  }
                  id={item.id}
                  meatballOpened={meatballOpened}
                  meatballOpenedObserver={(state) => setMeatballOpened(state)}
                />
              </div>
            </div>
          </div>
        );
      })}
      <AddUnitsModal
        key="edit-unit"
        unitsEdit={openEditUnitModal}
        isOpen={openEditUnitModal}
        unit={unitSelected}
        onClose={() => {
          setEditUnitModalOpen(false);
          setSelectedUnit(null);
        }}
      />
      <DeleteUnitModal
        key="delete-unit"
        unit={unitSelected}
        isOpen={openDeleteUnitModal}
        onClose={() => {
          setDeleteUnitModalOpen(false);
          setSelectedUnit(null);
        }}
      />
    </div>
  );
};

UnitContent.propTypes = {
  items: PropTypes.array,
};

export default UnitContent;
