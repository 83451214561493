import React, { useContext, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./TaskContent.module.scss";
import { TaskState } from "../../constants/Tasks";
import { I18nContext } from "../../i18n/I18nContext";
import TaskPreviewModal from "./TaskPreviewModal";
import history from "../../history";
import { generatePath } from "react-router-dom";
import { differenceInCalendarDays } from "date-fns";

const TaskContent = ({
  items,
  displayBorder,
  disabled,
  assetId,
  getAssetDetails,
  assetDetailsTaskList,
  isFinishedEditable,
}) => {
  const { t } = useContext(I18nContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const classBorder = cx(styles.task_items, {
    [styles.bordered]: displayBorder,
  });

  const taskActionDelayUnitsOptions = [
    { value: "DAYS", label: t("days") },
    { value: "WEEKS", label: t("weeks") },
    { value: "MONTHS", label: t("months") },
    { value: "QUARTER_YEAR", label: t("quarter year") },
    { value: "HALF_YEAR", label: t("half year") },
    { value: "YEARS", label: t("years") },
  ];
  const handleOpenModal = (itemId) => {
    setCurrentItemId(itemId);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentItemId(null);
  };

  const handleOpenDetails = (assetId) => {
    history.push(generatePath("/assets/details/:assetId", { assetId }));
  };

  return (
    <>
      <div className={styles.task_container}>
        {!!items &&
          !!items.length &&
          items.map((item) => (
            <div
              className={classBorder}
              key={item.id}
              data-testid={`task-content-${item.id}`}
              onClick={() => handleOpenModal(item.id)}
            >
              <div className={styles.row}>
                <div className={styles.subtitle}>
                  {item.name}
                  {!!item.scheduler && (
                    <span className={styles.recurrence}>
                      {"("}
                      {t("recurs every")}: {item.scheduler.recurringInterval}{" "}
                      {
                        taskActionDelayUnitsOptions.find(
                          (x) => x.value === item.scheduler.intervalUnit
                        )?.label
                      }
                      {")"}
                    </span>
                  )}
                  <div className={styles.description_tooltip}>{item.desc}</div>
                </div>

                <div>
                  {item.state === TaskState.TODO && item.daysLeft >= 0 && (
                    <span className={styles.ready}>{t("due", "upper")}</span>
                  )}
                  {item.state === TaskState.COMPLETED && (
                    <div className={styles.ready}>
                      {t("ready", "upper")}
                      {/* <Icon name="ready" /> */}
                      <div>
                        {item.haveDiscrepancies
                          ? t("with nonconformities", "lower")
                          : t("without nonconformities", "lower")}
                      </div>
                    </div>
                  )}
                  {item.state === TaskState.DONE_TOO_LATE && (
                    <div className={styles.late}>
                      {t(
                        item.state.replaceAll("_", " ").toLowerCase(),
                        "upper"
                      )}
                      <div>
                        {item.haveDiscrepancies
                          ? t("with nonconformities", "lower")
                          : t("without nonconformities", "lower")}
                      </div>
                    </div>
                  )}
                  {item.state === TaskState.OVERDUE && (
                    <div className={styles.late}>
                      {t(
                        item.state.replaceAll("_", " ").toLowerCase(),
                        "upper"
                      )}
                    </div>
                  )}
                  {(item.state === TaskState.MISSED ||
                    item.state === TaskState.CANT_DO ||
                    item.state === TaskState.CANCELLED) && (
                    <div className={styles.unready}>
                      {t(
                        item.state.replaceAll("_", " ").toLowerCase(),
                        "upper"
                      )}
                      {/* <Icon name="unready" /> */}
                    </div>
                  )}
                  {item.state === TaskState.SUSPENDED && (
                    <span className={styles.unready}>
                      {t(item.state.toLowerCase(), "upper")}
                    </span>
                  )}
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>
                  {!assetDetailsTaskList &&
                    !!item.assetPath &&
                    !!item.assetPath.length &&
                    item.assetPath.length > 1 && (
                      <span className={styles.path}>
                        {item.assetPath.slice(0, -1).join(" / ") + " / "}
                        <span
                          className={styles.asset_name}
                          onClick={() => handleOpenDetails(item.assetId)}
                        >
                          {item.assetPath.at(-1)}
                        </span>
                      </span>
                    )}
                  {!assetDetailsTaskList &&
                    !!item.assetPath &&
                    !!item.assetPath.length &&
                    item.assetPath.length === 1 && (
                      <span className={styles.path}>
                        <span
                          className={styles.asset_name}
                          onClick={() => handleOpenDetails(item.assetId)}
                        >
                          {item.assetPath.at(-1)}
                        </span>
                      </span>
                    )}
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.left}>
                  {(item.state === TaskState.TODO ||
                    item.state === TaskState.OVERDUE ||
                    item.state === TaskState.SUSPENDED) && (
                    <span
                      className={
                        item.state === TaskState.OVERDUE || item.daysLeft < 0
                          ? styles.title_open
                          : styles.title
                      }
                    >
                      {t("due date")}:{" "}
                      {new Date(
                        item.dueDate.split("T").shift()
                      ).toLocaleDateString(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                      {" / "}
                      {Math.abs(item.daysLeft)}{" "}
                      {item.daysLeft >= 0 ? t("days left") : t("days ago")}
                    </span>
                  )}
                  {(item.state === TaskState.COMPLETED ||
                    item.state === TaskState.DONE_TOO_LATE ||
                    item.state === TaskState.MISSED ||
                    item.state === TaskState.CANT_DO ||
                    item.state === TaskState.CANCELLED) && (
                    <span className={styles.title_ready}>
                      {t("finished")}:{" "}
                      {new Date(
                        item.history.at(-1).occurredAt
                      ).toLocaleDateString(undefined, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}{" "}
                      {new Date(
                        item.history.at(-1).occurredAt
                      ).toLocaleTimeString(undefined, { hour12: false })}
                      {item.state === TaskState.COMPLETED ||
                      item.state === TaskState.DONE_TOO_LATE
                        ? new Date(item.dueDate.split("T").shift()) >=
                          new Date(
                            item.history.at(-1).occurredAt.split("T").shift()
                          )
                          ? " / " + t("on time")
                          : " / " +
                            differenceInCalendarDays(
                              new Date(
                                item.history
                                  .at(-1)
                                  .occurredAt.split("T")
                                  .shift()
                              ),
                              new Date(item.dueDate.split("T").shift())
                            ) +
                            " " +
                            t("days too late", "lower")
                        : null}
                    </span>
                  )}
                </div>
              </div>

              {(item.state === TaskState.TODO ||
                item.state === TaskState.OVERDUE ||
                item.state === TaskState.SUSPENDED) && (
                <div className={styles.row}>
                  <div className={styles.left}>
                    {!!item.createdBy && (
                      <div className={styles.created}>
                        {t("created by")}
                        {": "}
                        {item.createdBy}
                        {" - "}
                        {new Date(
                          item.createdAt.split("T").shift()
                        ).toLocaleDateString(undefined, {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </div>
                    )}
                    {!!item.ruleName && (
                      <span className={styles.created}>
                        {t("task created by rule") + ": " + item.ruleName}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {(item.state === TaskState.COMPLETED ||
                item.state === TaskState.DONE_TOO_LATE ||
                item.state === TaskState.MISSED ||
                item.state === TaskState.CANT_DO ||
                item.state === TaskState.CANCELLED) && (
                <div className={styles.row}>
                  <div className={styles.left}>
                    {item.history.at(-1).type === "TIMEOUT" && (
                      <span className={styles.created}>
                        {t("finished by system")}
                      </span>
                    )}
                    {!!item.history.at(-1).user.userName &&
                      !item.isCanceledByRule && (
                        <span className={styles.created}>
                          {t("finished by")}:{" "}
                          {item.history.at(-1).user.userName}
                        </span>
                      )}
                    {!!item.isCanceledByRule && (
                      <span className={styles.created}>
                        {t("finished by")}: {item?.canceledByRuleName}{" "}
                        {t("of", "lower")}{" "}
                        {item?.prototypeNameFromRuleWhichCanceled}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      {!!isModalOpen && (
        <TaskPreviewModal
          assetId={assetId}
          disabled={disabled}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          itemId={currentItemId}
          getAssetDetails={getAssetDetails}
          isFinishedEditable={isFinishedEditable}
        />
      )}
    </>
  );
};

TaskContent.propTypes = {
  items: PropTypes.array,
  displayBorder: PropTypes.bool,
};

export default TaskContent;
