export const filterItems = (
  items,
  fromDateFilter,
  toDateFilter,
  stateFilter,
  templateFilter,
  nameFilter,
  closedTasks,
  nearlyMissedFilter
) => {
  //TODO:REFACTOR
  return items
    .filter((item) => {
      if (nameFilter) {
        return item.assetId === nameFilter.value;
      } else {
        return true;
      }
    })
    .filter((item) => {
      if (templateFilter && templateFilter.length) {
        return templateFilter
          .map((option) => option.value)
          .includes(item.prototypeId);
      } else {
        return true;
      }
    })
    .filter((item) => {
      if (stateFilter && stateFilter.length) {
        if (closedTasks) {
          return (
            stateFilter.map((option) => option.value).includes(item.state) ||
            (stateFilter
              .map((option) => option.value)
              .includes("COMPLETED_WITHOUT_DISCREPANCIES") &&
              item.state === "COMPLETED" &&
              !item.haveDiscrepancies) ||
            (stateFilter
              .map((option) => option.value)
              .includes("COMPLETED_WITH_DISCREPANCIES") &&
              item.state === "COMPLETED" &&
              item.haveDiscrepancies) ||
            (stateFilter
              .map((option) => option.value)
              .includes("DONE_TOO_LATE_WITHOUT_DISCREPANCIES") &&
              item.state === "DONE_TOO_LATE" &&
              !item.haveDiscrepancies) ||
            (stateFilter
              .map((option) => option.value)
              .includes("DONE_TOO_LATE_WITH_DISCREPANCIES") &&
              item.state === "DONE_TOO_LATE" &&
              item.haveDiscrepancies)
          );
        } else if (
          stateFilter.map((option) => option.value).includes("RECURRING") &&
          item.state === "TODO" &&
          !!item.isRecursive
        ) {
          return true;
        } else if (
          stateFilter.map((option) => option.value).includes("TODO") &&
          item.state === "TODO" &&
          !item.isRecursive
        ) {
          return true;
        } else if (item.state !== "TODO") {
          return stateFilter.map((option) => option.value).includes(item.state);
        }
      } else {
        return true;
      }
    })
    .filter((item) => {
      if (fromDateFilter) {
        const date = formatDate(fromDateFilter);
        return closedTasks
          ? item?.finishDate?.split("T").shift() >= date
          : item?.dueDate?.split("T").shift() >= date;
      } else {
        return true;
      }
    })
    .filter((item) => {
      if (toDateFilter) {
        const date = formatDate(toDateFilter);
        return closedTasks
          ? item?.finishDate?.split("T").shift() <= date
          : item?.dueDate?.split("T").shift() <= date;
      } else {
        return true;
      }
    })
    .filter((item) => {
      const today = new Date();
      const priorDate = new Date(new Date().setDate(today.getDate() + 3));
      const taskDueDate = new Date(item.dueDate);
      if (nearlyMissedFilter) {
        return today < taskDueDate && priorDate > taskDueDate;
      } else {
        return true;
      }
    });
};

export const formatDate = (date) => {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString().split("T")[0];
};

export const createProperSensorObject = (
  assetId,
  sensorId,
  name,
  properties,
  units
) => {
  let propertiesMap = {};

  properties.forEach((property) => {
    propertiesMap[property.name] = {
      property: {
        propertyType: "numeric",
        decimalPoints: 0,
        description: "string",
        flags: {
          isConfigurable: true,
          isMandatory: true,
          isUpdatable: true,
        },
        hasRange: false,
        name: property.name,
        range: {
          lowerBound: 0,
          step: 0,
          upperBound: 0,
        },
        unit: {
          ...units.find((el) => el.id === property.unitId),
        },
        value: 0,
      },
      procedureIds: property.procedureIds,
    };
  });

  return {
    assetId,
    id: sensorId,
    name,
    properties: propertiesMap,
  };
};

export const parseProperties = (properties, units) => {
  const parsed = [];
  Object.values(properties).forEach((el) =>
    parsed.push({
      procedureIds: el.procedureIds,
      name: el.property.name,
      unitId: units.find((unit) => unit.name === el.property.unit.name).id,
    })
  );
  return parsed;
};

const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const parseSensorChartData = (data) => {
  data.sort(function (a, b) {
    return new Date(a.date) - new Date(b.date);
  });
  const parsedData = [];

  data.forEach((element) => {
    const day = new Date(element.date).getDay();
    const values = {};
    for (const [key, value] of Object.entries(element.properties)) {
      values[key] = value;
    }

    parsedData.push({
      label: days[day],
      ...values,
    });
  });
  return parsedData;
};

export const parseVolatilityData = (data) => {
  data.sort(function (a, b) {
    return new Date(a.secondMeasurement) - new Date(b.secondMeasurement);
  });
  const parsedData = [];

  data.forEach((element) => {
    const day = new Date(element.secondMeasurement).getDay();
    const values = {};
    for (const [key, value] of Object.entries(element.properties)) {
      values[key] = value;
    }

    parsedData.push({
      label: days[day],
      first: element.firstMeasurement,
      second: element.secondMeasurement,
      ...values,
    });
  });
  return parsedData;
};

export const getProperDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${days[day]}  ${hours}:${
    minutes.toString().length === 1 ? `0${minutes}` : `${minutes}`
  }:${seconds}`;
};
