import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  fetchAssetPrototypes,
  fetchRegisteredCompanies,
  // fetchRegisteredRights,
  fetchRegisteredRoles,
  fetchRegisteredUsersList,
  fetchRightsAssetTemplateList,
  fetchRightsList,
  fetchRightsTaskTemplateList,
  fetchTasksPrototypesList,
  fetchFilteredAssets,
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import SectionHeader from "../SectionHeader";
import { Tab, TabContent, Tabs, TabsBar } from "../Tabs";
import AccessUserContent from "./AccessUserContent";
import AccessCompaniesContent from "./AccessCompaniesContent";
import MaintainerContent from "./MaintainerContent";
import RightContent from "./RightContent";
import styles from "./Access.module.scss";
import { I18nContext } from "../../i18n/I18nContext";
import { getMaintainersOrganizationList } from "../../redux/maintainers/maintainersActions";
import jwtDecode from "jwt-decode";
import NotificationGroup from "../NotificationGroups/NotificationGroup";
import { fetchNotificationGroups } from "../NotificationGroups/notificationGroupActions";

const Access = () => {
  const { t } = useContext(I18nContext);
  const dispatch = useDispatch();
  const userItems = useSelector((state) => state.access.userItems);
  const maintainerOrgs = useSelector(
    (state) => state.maintainers.maintainerOrganizationsList
  );
  const rightsItems = useSelector((state) => state.rights.items);
  const companies = useSelector((state) =>
    state.access.companiesItems.sort((a, b) => a.name.localeCompare(b.name))
  );
  const notificationGroups = useSelector((state) => {
    return state.notificationGroups.items;
  });
  const info = useSelector((state) => state.auth.info);

  const role = info?.role;
  const isSuperAdmin = info?.token
    ? jwtDecode(info?.token).role === "SUPER_ADMIN"
    : false;

  const isAdmin = info?.token ? jwtDecode(info?.token).role === "ADMIN" : false;

  const isPrivilegedCompany = info?.token
    ? jwtDecode(info?.token).companyPrivileged
    : false;

  const [selectedTab, setSelectedTab] = useState(
    (isSuperAdmin || isAdmin) && isPrivilegedCompany ? "customers" : "users"
  );

  const handleChange = useCallback(
    (newValue) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab]
  );

  useEffect(() => {
    dispatch(fetchRegisteredUsersList());
    dispatch(fetchRegisteredRoles());
    dispatch(fetchRegisteredCompanies());
    dispatch(fetchRightsList());
    dispatch(fetchRightsTaskTemplateList());
    dispatch(fetchRightsAssetTemplateList());
    dispatch(getMaintainersOrganizationList());
    dispatch(fetchAssetPrototypes());
    dispatch(fetchTasksPrototypesList());
    dispatch(fetchFilteredAssets({}));
    dispatch(fetchNotificationGroups());
  }, [dispatch]);
  return (
    <>
      <SectionHeader>
        <div className={styles.title}>{t("access")}</div>
      </SectionHeader>
      <Tabs
        onChange={handleChange}
        initialValue={role === "CUSTOMER" ? "maintainer" : selectedTab}
      >
        <TabsBar>
          {(isSuperAdmin || isAdmin) && isPrivilegedCompany && (
            <Tab key={"customers"} value={"customers"}>
              <div className={styles.row_tab}>
                {t("customers")}
                <span className={styles.circle}>
                  <span className={styles.number}>{companies.length}</span>
                </span>
              </div>
            </Tab>
          )}
          {role !== "CUSTOMER" && (
            <Tab key={"users"} value={"users"}>
              <div className={styles.row_tab}>
                {t("users")}
                <span className={styles.circle}>
                  <span className={styles.number}>
                    {userItems?.length ?? 0}
                  </span>
                </span>
              </div>
            </Tab>
          )}
          <Tab key={"rights"} value={"rights"}>
            <div className={styles.row_tab}>
              {t("access rights")}
              <span className={styles.circle}>
                <span className={styles.number}>{rightsItems.length}</span>
              </span>
            </div>
          </Tab>
          <Tab key={"maintainer"} value={"maintainer"}>
            <div className={styles.row_tab}>
              {t("maintainer organization")}
              <span className={styles.circle}>
                <span className={styles.number}>{maintainerOrgs.length}</span>
              </span>
            </div>
          </Tab>
          <Tab key={"notificationGroups"} value={"notificationGroups"}>
            <div className={styles.row_tab}>
              {t("notification groups")}
              <span className={styles.circle}>
                <span className={styles.number}>
                  {notificationGroups.length}
                </span>
              </span>
            </div>
          </Tab>
        </TabsBar>
        {role !== "CUSTOMER" && (
          <TabContent index="users">
            <AccessUserContent items={userItems} />
          </TabContent>
        )}
        <TabContent index="maintainer">
          <MaintainerContent items={maintainerOrgs} />
        </TabContent>
        <TabContent index="rights">
          <RightContent
            items={rightsItems}
            privilegedCompany={isPrivilegedCompany}
          />
        </TabContent>
        <TabContent index="notificationGroups">
          <NotificationGroup items={notificationGroups} />
        </TabContent>
        {(isSuperAdmin || isAdmin) && isPrivilegedCompany && (
          <TabContent index="customers">
            <AccessCompaniesContent items={companies} />
          </TabContent>
        )}
      </Tabs>
    </>
  );
};

export default Access;
