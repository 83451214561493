import React, { useContext } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import styles from "./PieChartView.module.scss";
import { I18nContext } from "../../../i18n/I18nContext";

const PieChartView = (
  { statusOfCompleted, statusOfOpen, data, texts, colors, onClick }
) => {
  const { t } = useContext(I18nContext);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>
            {statusOfOpen && payload[0].payload.isRecurring ? texts["RECURRING"] : texts[payload[0].payload.state]}
          </p>
          <p>{`${t("count")}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomTooltipStatus = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.label}>
            {texts[payload[0].payload.status]}
          </p>
          <p>{`${t("count")}: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <PieChart
      width={210}
      height={210}
      onMouseEnter={() => {
        return null;
      }}
    >
      <Pie
        data={data}
        cx={100}
        cy={100}
        innerRadius={50}
        outerRadius={100}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="count"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              statusOfOpen && entry.isRecurring
                ? colors["RECURRING"]
                : statusOfCompleted
                  ? colors[entry.status]
                  : colors[entry.state] || "#f9dc76"
            }
            onClick={
              () => onClick(
                statusOfOpen
                  ? 'open'
                  : statusOfCompleted
                    ? 'result'
                    : 'closed',
                entry.isRecurring
                  ? "RECURRING"
                  : entry.status ?? entry.state
              )
            }
          />
        ))}
      </Pie>
      <Tooltip
        content={
          statusOfCompleted ? <CustomTooltipStatus /> : <CustomTooltip />
        }
      />
    </PieChart>
  );
};

export default PieChartView;
